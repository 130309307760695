import React from 'react';
import { graphql, HeadProps } from 'gatsby';
import SomethingWentWrong from 'pageComponents/SomethingWentWrong';

const SomethingWentWrongPage = () => {
  return <SomethingWentWrong />;
};

export default SomethingWentWrongPage;

export function Head(props: HeadProps) {
  return <>
    <title>Something Went Wrong | Safebrok</title>
    <meta name='robots' content='noindex, nofollow'/>
  </>;
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
