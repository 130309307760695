import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import styled from 'styled-components';
import { breakpoints } from 'theme';
import { rem } from 'polished';

import SomethingWentWrongIcon from 'images/SomethingWentWrongIcon.svg';
import BottomLeftIcon from 'images/404bottomLeftIcon.svg';
import TopRightIcon from 'images/TopRightIcon.svg';
import Button from 'components/Button';

import Header from 'components/Header';
import NavigationColor from 'utils/constants/NavigationColor';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1064px;
  width: 100%;
  position: relative;
`;

const NoResultSection = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin: 8px 0 300px 0;

    @media (max-width: ${breakpoints.tablet}) {
      line-height: ${rem(25)};
      margin: 16px 0 240px 0;
    }
  }
`;

const ErrorIconImage = styled.img`
  margin: 170px 0 32px 0;

  @media (max-width: ${breakpoints.tablet}) {
    width: 180px;
    height: 170px;
  }
`;

const MessageTitle = styled.h5`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  margin: 32px 0 0 0;

  @media (max-width: ${breakpoints.tablet}) {
    line-height: ${rem(27)};
    font-size: ${rem(24)};
    margin: 16px 0 10px 0;
  }
`;

const MessageBody = styled.h6`
  color: ${(props) => props.theme.neutralPalette.neutral8};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  margin: 8px 0 40px 0;

  @media (max-width: ${breakpoints.tablet}) {
    line-height: ${rem(24)};
    font-size: ${rem(16)};
    margin: 16px 20px 40px 20px;
  }
`;

const TopRightIconImage = styled.img`
  position: absolute;
  top: 90px;
  right: -30%;

  @media (max-width: ${breakpoints.tablet}) {
    width: 220px;
    right: -15%;
    top: 10%;
  }
`;

const BottomLeftIconImage = styled.img`
  position: absolute;
  bottom: 0;
  left: -30%;

  @media (max-width: ${breakpoints.tablet}) {
    width: 220px;
    left: -25%;
    bottom: -15%;
  }
`;
const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header variant={NavigationColor.DARK} />
      <Container>
        <NoResultSection>
          <ErrorIconImage
            src={SomethingWentWrongIcon}
            alt=""
            aria-hidden="true"
          />
          <MessageTitle>{t('ss_generic_error.h1')}</MessageTitle>
          <MessageBody>{t('ss_generic_error.body')}</MessageBody>
          <Button href="/"> Refresh Page </Button>
          <TopRightIconImage src={TopRightIcon} alt="" aria-hidden="true" />
          <BottomLeftIconImage src={BottomLeftIcon} alt="" aria-hidden="true" />
        </NoResultSection>
      </Container>
    </>
  );
};

export default NotFound;
